import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Col, Row } from "reactstrap";
import ValluLogoSvg from "../images/_Vallu_musta.svg";

export default function CalculatorGuidePage() {
  const { t } = useTranslation("common");

  return (
    <>
      <main className="container-fluid">
        <section className="row">
          <Col />
          <Col xs={10}>
            <Breadcrumb>
              <BreadcrumbItem>
                <Link to="/">{t("frontPage")} </Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>{t("calculatorGuidePage")}</BreadcrumbItem>
            </Breadcrumb>

            <div className="mb-5">
              <h2 className="header2">{t("calculatorGuide.title")}</h2>
              <p>{t("calculatorGuide.infoText")}</p>
            </div>

            <div className="mb-5">
              <h2 className="header2">
                {t("calculatorGuide.functions.title")}
              </h2>
              <ul>
                {[...Array(9).keys()].map((key) => (
                  <li key={`calculatorGuide.functions.items.${key}`}>
                    <Trans
                      defaults={t(`calculatorGuide.functions.items.${key}`)}
                      components={{ sub: <sub /> }}
                    />
                  </li>
                ))}
              </ul>
            </div>

            <div className="mb-5">
              <h2 className="header2">
                {t("calculatorGuide.functions.example")}
              </h2>
              <div className="mt-5">
                <img
                    alt=""
                    className="img-fluid"
                    src={require(`../images/Laskin1.png`)}
                />
              </div>
              <div className="mt-5">
                <img
                    alt=""
                    className="img-fluid"
                    src={require(`../images/Laskin2.png`)}
                />
              </div>
              <div className="mt-5">
                <img
                    alt=""
                    className="img-fluid"
                    src={require(`../images/Laskin3.png`)}
                />
              </div>
            </div>

            <div className="mb-5">
              <Link to="/">{t("toFrontPage")} </Link>
            </div>
          </Col>
          <Col/>
        </section>
      </main>
      <footer className="container-fluid">
        <Row className="py-4">
          <Col xs={6} className="order-1 order-sm-0 h-100 align-self-end">
            <img
              alt="Vallu logo"
              className="img-fluid"
              src={ValluLogoSvg}
              style={{ height: "3rem" }}
            />
          </Col>
          <Col xs={6} className="order-2 h-100 align-self-end text-end">
            <p className="mb-0">&copy; {new Date().getFullYear()} Metropolia</p>
          </Col>
        </Row>
      </footer>
    </>
  );
}
